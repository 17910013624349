import * as React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import '../components/css/typography.css';
import { v4 as uuidv4 } from 'uuid';
import SEO from '../components/seo';
import Header from '../components/header';
import Post from '../components/post';

// styles
const pageStyles = {
  color: 'rgb(0 255 0)',
  backgroundColor: 'black',
  overflowX: 'hidden',
  margin: 0,
  fontFamily: 'Press_Start, Roboto, sans-serif, serif',
  minHeight: '100vh',
};

const titleStyle = {
  marginTop: '75px',
};

const IndexPage = ({ data, pageContext }) => {
  const { numPages, currentPage, soundLinks } = pageContext;
  const posts = data.allContentfulPost.edges;
  const prev = currentPage === 2 ? '/' : `/posts/${currentPage - 1}`;
  const next = `/posts/${currentPage + 1}`;
  return (
    <div>
      <SEO title="Shitty Mashups" />
      <Header />
      <main style={pageStyles}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <center>
                <p className="fs-2" style={titleStyle}>
                  SHITTY MASHUPS
                </p>
                <p>
                  A Music Podcast for People who Hate Music
                </p>
              </center>
            </div>
          </div>
          {
            posts.map((postInfo, i) => (
              <Post key={uuidv4()} data={postInfo.node} soundLink={soundLinks[i]} />
            ))
          }
          <div className="row">
            <div className="mt-3 col-2">
              <center>
                <a href={prev} className={`nes-btn is-success${currentPage === 1 ? ' d-none' : ''}`}>PREV</a>
              </center>
            </div>
            <div className="mt-3 col-2 offset-6 offset-md-8">
              <center>
                <a className={`nes-btn is-success ${currentPage === numPages ? ' d-none' : ''}`} href={next}>NEXT</a>
              </center>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export const query = graphql`
  query postsPagesQuery($skip: Int!, $limit: Int!){
    allContentfulPost(
      sort: {fields: publishDate, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          episodeName
          id
          publishDate
          image {
            file {
              fileName
              url
            }
          }
          audioLocation
          description {
            raw
          }
        }
      }
    }
  }
`;

export default IndexPage;

IndexPage.propTypes = {
  data: PropTypes.shape({
    allContentfulPost: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({}),
      ),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    numPages: PropTypes.number,
    currentPage: PropTypes.number,
    soundLinks: PropTypes.arrayOf(
      PropTypes.string,
    ),
  }).isRequired,
};
