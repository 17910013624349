import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import './css/typography.css';

/* eslint-disable jsx-a11y/media-has-caption */

const Player = ({ link }) => {
  const [active, setActive] = useState(0);
  const handleClick = () => {
    setActive(true);
  };

  if (active) {
    return (
      <audio controls style={{ width: '100%' }} className="mt-3">
        <source src={link} type="audio/mpeg" />
        Your browser does not support the audio element
      </audio>
    );
  }
  return (
    <button
      type="button"
      onClick={handleClick}
      className="mt-3 nes-btn"
    >
      play
    </button>
  );
};

const Post = ({ data, soundLink }) => {
  const {
    episodeName, image, description,
  } = data;
  const parsedDescription = JSON.parse(description.raw).content[0].content[0].value;
  return (
    <div className="row">
      <div className="col-12">
        <div style={{
          margin: '24px auto', border: '3px dashed rgb(0 255 0)', padding: '24px', maxWidth: '800px',
        }}
        >
          <div className="row">
            <div className="col-12">
              <center>
                <span className="fs-6">{episodeName}</span>
              </center>
            </div>
            <div className="col-xs-12 col-lg-6">
              <img style={{ marginTop: '20px', imageRendering: 'pixelated', maxWidth: '100%' }} alt={image.file.fileName} src={image.file.url} />
            </div>
            <div className="mt-3 mt-lg-0 col-xs-12 col-lg-6">
              <p style={{ marginTop: '16px', fontSize: '12px' }}>{parsedDescription}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Player link={soundLink} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;

Post.propTypes = {
  data: PropTypes.shape({
    episodeName: PropTypes.string,
    image: PropTypes.shape({
      file: PropTypes.shape({
        fileName: PropTypes.string,
        url: PropTypes.string,
      }),
    }),
    audioLocation: PropTypes.string,
    description: PropTypes.shape({
      raw: PropTypes.string,
    }),
  }).isRequired,
  soundLink: PropTypes.string.isRequired,
};

Player.propTypes = {
  link: PropTypes.string.isRequired,
};
